import React from 'react';
import { Event } from '../../types/events.types';
import { dateToString } from '../../../utilities/utilities';
import styles from './upcoming_event.module.scss';
import { EVENTS_PATH } from '../../../utilities/devex_paths';
const CLOUDINARY_BASE_PATH = 'https://res.cloudinary.com/devex/image';
const FALLBACK_IMAGE_FILENAME = 'devex-generic-cccdb08fd0bb6bcf8a8a2d3b6c21a76b.png';
const rowMaxHeight = 188;

const EVENT_PREVIEW_IMAGE_PATH_FOR_XS_AND_DESKTOP = `
  ${CLOUDINARY_BASE_PATH}/fetch/c_fill,q_auto,h_${rowMaxHeight},w_272/
`;

const FALLBACK_EVENT_IMAGE_URL_FOR_XS_AND_DESKTOP = `
  ${CLOUDINARY_BASE_PATH}/asset/c_fill,q_auto,h_${rowMaxHeight},w_272/${FALLBACK_IMAGE_FILENAME}
`;

const EVENT_PREVIEW_IMAGE_PATH_FOR_SM = `
  ${CLOUDINARY_BASE_PATH}/fetch/c_fill,q_auto,h_${rowMaxHeight},w_520/
`;

const FALLBACK_EVENT_SRC_URL_FOR_SM = `
  ${CLOUDINARY_BASE_PATH}/asset/c_fill,q_auto,h_${rowMaxHeight},w_520/${FALLBACK_IMAGE_FILENAME}
`;

interface Props {
  event: Event;
}

const UpcomingEvent: React.FC<Props> = ({ event }) => {
  const EVENT_PATH = `${EVENTS_PATH}/${event.slug_and_id}`;

  const eventImageUrlForMobile = event?.preview_image
    ? EVENT_PREVIEW_IMAGE_PATH_FOR_SM + event?.preview_image
    : FALLBACK_EVENT_SRC_URL_FOR_SM;

  const eventImageUrlForDesktop = event?.preview_image
    ? EVENT_PREVIEW_IMAGE_PATH_FOR_XS_AND_DESKTOP + event?.preview_image
    : FALLBACK_EVENT_IMAGE_URL_FOR_XS_AND_DESKTOP;

  const renderImageForDesktop = (
    <div className='d-none d-md-block'>
      <img src={eventImageUrlForDesktop} alt={event.title} className='img-fluid' />
    </div>
  );

  const renderImageForMobile = (
    <div className='d-block d-md-none'>
      <img src={eventImageUrlForMobile} alt={event.title} className='card-img-top' />
    </div>
  );

  return (
    <div className='row'>
      <div className='card border-0'>
        <div className={`row g-0 ${styles.upcomingEvent}`}>
          <div className='col-md-4'>
            {renderImageForMobile}
            {renderImageForDesktop}
          </div>
          <div className='col-md-8'>
            <a href={EVENT_PATH} className='stretched-link'>
              <div className='card-body pt-lg-45'>
                <h3 className='card-title mb-0 fs-3 lh-sm devex-font-family-serif text-gray-dark'>
                  {event.title}
                </h3>
                <p className='card-text fs-6 fst-italic devex-font-family-serif'>
                  {dateToString(event.starts_at)}
                </p>
                <div className='d-block d-md-none'>
                  <p className='card-text text-gray-li stretched-link'>{event?.preview_text}</p>
                </div>
                <div className='d-none d-md-block'>
                  <p className='card-text text-gray-li stretched-link text-truncate'>
                    {event?.preview_text}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvent;
